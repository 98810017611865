<template>
  <div class="header minwidth">
    <div class="header-nav clearboth">
      <div class="header-nav-title floatleft">宜信云</div>

      <!--
      <div class="header-nav-list floatleft">
        <router-link :class="['header-nav-list-item',{'header-nav-list-item-active':actived=='/home'}]" to="/">首页</router-link>
        <router-link :class="['header-nav-list-item',{'header-nav-list-item-active':actived=='/product'}]" to="/product" >产品中心</router-link>
        <router-link :class="['header-nav-list-item',{'header-nav-list-item-active':actived=='/help'}]" to="/help" >使用帮助</router-link>
        <router-link :class="['header-nav-list-item',{'header-nav-list-item-active':actived=='/about'}]" to="/about" >关于我们</router-link >
        <router-link :class="['header-nav-list-item',{'header-nav-list-item-active':actived=='/education'}]" to="/education">免费试用</router-link>
      </div>
      <div class="header-nav-right floatleft">
        <p class="header-nav-right-login">登录</p>
        <p class="header-nav-right-register">注册</p>
      </div>
      -->
    </div>
  </div>
</template>

<script>
    export default {
        data() {
          return {};
        },
        methods: {
        },
        computed:{
          actived(){
            return this.$route.path
          }
        },
    }
</script>

<style lang="scss" scoped>
.header{
    position: fixed;
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;
    background: $theme-color;
    z-index: 100;
    // background: url("../assets/images/home/bg.png") no-repeat;
    // background-size: 100% 100%;
    &-nav{
      margin: 0 auto;
      width: 1050px;
      &-title{
        font-size: 28px;
        color: #fff;
        line-height: 55px;
      }
      &-list{
        width: 550px;
        margin: 0 50px;
        &-item{
          display: inline-block;
          height: 55px;
          line-height: 55px;
          padding:0 10px;
          box-sizing: border-box;
          text-decoration: none;
          cursor: pointer;
          font-size: 15px;
          color: #fff;
          &-active{
            color: $active-text-color;
            border-bottom: 1px solid $active-text-color;
          }
          &:hover{
            color: $active-text-color;
            border-bottom: 1px solid $active-text-color;
          }
          &+&{
            margin-left: 30px;
          }
        }
      }
      &-right{
        line-height: 55px;
        width: 300px;
        p{
          display: inline-block;
          width: 100px;
          height: 34px;
          line-height: 34px;
          font-size: 14px;
          text-align: center;
          vertical-align: middle;
          border: 1px solid #fff;
          cursor: pointer;
          border-radius: 2px;
          margin-right: 10px;
        }
        &-login{
          color: #fff;
          &:hover{
            background-color: #fff;
            color: #000;
          }

        }
        &-register{
          background-color: #fff;
          color: #000 ;
          &:hover{
            background-color: $active-text-color;
            border: 1px solid $active-text-color;
            color: #fff;
          }

        }
      }
    }
}
</style>